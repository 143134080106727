import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import {
  removeEntity,
  setAllEntities,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { omit } from 'radashi';
import { filter, pipe, switchMap, tap } from 'rxjs';
import { SetRequired } from 'type-fest';

import { User } from '@rpm/shared/schemas';

import { OrgStore } from '../../org/org.store';
import { setLoadedAllInOrgId, withOrgId } from '../../org/with-org-id.feature';
import {
  setError,
  setFulfilled,
  setPending,
  withRequestStatus,
} from '../../shared/state/request-status.feature';

import { UserService } from './user.service';
import { UserShopStore } from './user-shop.store';

export const UserStore = signalStore(
  { providedIn: 'root' },
  withEntities<Omit<User, 'shops'>>(),
  withRequestStatus(),
  withOrgId(),
  withComputed(({ entities }) => ({
    activeSeatCount: computed(
      () =>
        entities().filter(
          (user) => !user.isBlocked && !user.isTest && user.isConfirmed,
        ).length,
    ),
    activeTestCount: computed(
      () =>
        entities().filter(
          (user) => !user.isBlocked && user.isTest && user.isConfirmed,
        ).length,
    ),
  })),
  withMethods(
    (
      store,
      userService = inject(UserService),
      orgStore = inject(OrgStore),
      userShopStore = inject(UserShopStore),
    ) => ({
      setUser({shops, ...user}: User) {
        if (user.orgId === orgStore.selectedEntityId()) {
          patchState(store, setEntity(user));
          orgStore.addGroups(Object.keys(user.groups));
          userShopStore.setAllForUser(user, shops ?? {});
        }
      },
      updateUser({
        id,
        orgId,
        ...changes
      }: SetRequired<Partial<User>, 'id' | 'orgId'>) {
        if (orgId === orgStore.selectedEntityId()) {
          patchState(store, updateEntity({ id, changes }));
          if (changes.groups) {
            orgStore.addGroups(Object.keys(changes.groups));
          }
          if (changes.shops) {
            const user = store.entityMap()[id];
            userShopStore.setAllForUser(user, changes.shops);
          }
        }
      },
      removeUser(id: string) {
        patchState(store, removeEntity(id));
        userShopStore.removeAllForUser(id);
      },
      loadAllInOrg: rxMethod<string>(
        pipe(
          filter((orgId) => orgId !== store._loadedAllInOrgId()),
          tap(() => patchState(store, setPending())),
          switchMap((orgId) =>
            userService.getAllInOrg(orgId).pipe(
              tapResponse({
                next: (users) => {
                  patchState(
                    store,
                    setAllEntities(users.map(user => omit(user, ['shops']))),
                    setFulfilled(),
                    setLoadedAllInOrgId(orgId),
                  );
                  userShopStore.setAll(
                    users.flatMap((user) =>
                      Object.entries(user.shops ?? {}).map(
                        ([shopId, { name: shopName, role }]) => ({
                          userId: user.id,
                          userName: user.name,
                          shopId,
                          shopName,
                          role,
                        }),
                      ),
                    ),
                  );
                },
                error: (err) => {
                  patchState(store, setError(err));
                },
              }),
            ),
          ),
        ),
      ),
    }),
  ),
);
