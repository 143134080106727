import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import {
  CreateUserBody,
  UpdateAccountBody,
  UpdateUserBody,
  User,
} from '@rpm/shared/schemas';

export interface CreateUserArgs extends CreateUserBody {
  orgId: string;
}

export interface UpdateUserArgs extends UpdateUserBody {
  orgId: string;
  id: string;
}

const apiUrl = import.meta.env.NG_APP_API_URL;

@Injectable({ providedIn: 'root' })
export class UserService {
  private http = inject(HttpClient);

  getAllInOrg(orgId: string) {
    return this.http.get<User[]>(`${apiUrl}/orgs/${orgId}/users`);
  }

  createUser({ orgId, ...body }: CreateUserArgs) {
    return this.http.post<User>(`${apiUrl}/orgs/${orgId}/users`, body);
  }

  resendInvite({ orgId, id }: { orgId: string; id: string }) {
    return this.http.post<void>(
      `${apiUrl}/orgs/${orgId}/users/${id}/resend-invite`,
      null,
    );
  }

  updateUser({ orgId, id, ...body }: UpdateUserArgs) {
    return this.http.put<Partial<User>>(
      `${apiUrl}/orgs/${orgId}/users/${id}`,
      body,
    );
  }

  deleteUser({ orgId, id }: { orgId: string; id: string }) {
    return this.http.delete<void>(`${apiUrl}/orgs/${orgId}/users/${id}`);
  }

  updateAccount(body: UpdateAccountBody) {
    return this.http.put<void>(`${apiUrl}/account`, body);
  }

  confirmAccount() {
    // calling the update account API also confirms the account
    return this.updateAccount({});
  }
}
