import {
  AfterContentInit,
  Directive,
  ElementRef,
  inject,
  OnDestroy,
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { Subscription } from 'rxjs';

import { FormErrorService } from '../error/form-error.service';

/**
 * Convenience directive for use with `<mat-error></mat-error>`.
 *
 * @example
 * <mat-form-field>
 *   <mat-label>Email address</mat-label>
 *   <input matInput formControlName="email">
 *   <mat-error appFormFieldError></mat-error>
 * </mat-form-field>
 */
@Directive({
  selector: '[appFormFieldError]',
})
export class FormFieldErrorDirective implements AfterContentInit, OnDestroy {
  private el = inject<ElementRef<HTMLElement>>(ElementRef);
  private formErrors = inject(FormErrorService);
  private formField = inject(MatFormField);

  private subscription?: Subscription;

  ngAfterContentInit(): void {
    this.subscription = this.formField._control.stateChanges.subscribe(() => {
      const control = this.formField._control.ngControl;
      this.el.nativeElement.innerText = control?.errors
        ? this.formErrors.describeValidationErrors(control.errors) || ''
        : '';
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
